import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
//import { JsonCMP } from './json/en.json';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Account Settings' } },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
            { path: 'about-us', loadChildren: () => import('./pages/aboutus/aboutus.module').then(m => m.aboutusModule), data: { breadcrumb: 'About Us' } },
            { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
            { path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule), data: { breadcrumb: 'Edit Profile ' } },
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' } },
            { path: 'Moms', loadChildren: () => import('./pages/Moms/moms.module').then(m => m.MomsModule), data: { breadcrumb: 'Moms' } },
            { path: 'Vendor', loadChildren: () => import('./pages/Vendor/vendors.module').then(m => m.VendorsModule), data: { breadcrumb: 'Vendor' } },
            { path: 'Categories', loadChildren: () => import('./pages/Categories/Categories.module').then(m => m.CategoriesModule), data: { breadcrumb: 'Category' } },
            { path: 'videocategories', loadChildren: () => import('./pages/videocategories/videocategories.module').then(m => m.videocategoriesModule), data: { breadcrumb: 'VideoCategory' } },
           //{ path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.Pagesmodule), data: { breadcrumb: 'pages' } },
           { path: 'subscription', loadChildren: () => import('./pages/subscription/subscription.module').then(m => m.SubscriptionModule), data: { breadcrumb: 'subscription' } },
           { path: 'store-list', loadChildren: () => import('./pages/store-listing/store-listing.module').then(m => m.StoreListingModule), data: { breadcrumb: 'store list' } },
           { path: 'store-details', loadChildren: () => import('./pages/store-details/store-details.module').then(m => m.StoreDetailsModule), data: { breadcrumb: 'store-details' } },
           { path: 'bouquet', loadChildren: () => import('./pages/bouquet/bouquet.module').then(m => m.BouquetModule), data: { breadcrumb: 'Create Your Bouquet' } },
           { path: 'bouquet-list', loadChildren: () => import('./pages/bouquetlist/bouquetlist.module').then(m => m.BouquetlistModule), data: { breadcrumb: 'bouquet-list' } },
           { path: 'subscrip-order', loadChildren: () => import('./pages/subscrip-order/subscrip-order.module').then(m => m.SubscripOrderModule), data: { breadcrumb: 'subscrip-order' } },
           { path: 'services-product', loadChildren: () => import('./pages/services-product/services-product.module').then(m => m.ServicesProductModule), data: { breadcrumb: 'Add Services' } },
           { path: 'bouquet-order-list', loadChildren: () => import('./pages/bouquet-order-list/bouquet-order-list.module').then(m => m.BouquetOrderListModule), data: { breadcrumb: 'bouquet order list' } },
           { path: 'PaymentSuccessful', loadChildren: () => import('./pages/payment-successful/payment-successful.module').then(m => m.PaymentSuccessfulModule), data: { breadcrumb: 'Payment Successful' } },
           { path: 'Paymentfail', loadChildren: () => import('./pages/payment-fail/payment-fail.module').then(m => m.PaymentFailModule), data: { breadcrumb: 'Payment fail' } },
           { path: 'forget-password', loadChildren: () => import('./pages/forget-password/forget-password.module').then(m => m.ForgetPasswordModule), data: { breadcrumb: 'Forget Password' } },
           { path: 'wallet-details', loadChildren: () => import('./pages/wallet-details/wallet-details.module').then(m => m.WalletDetailsModule), data: { breadcrumb: 'my Wallet' } },
           { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Products' } },
           { path: 'product', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule), data: { breadcrumb: 'Products Details' } },
           { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'Privacy-policy' } },

        ]
    },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    //{ path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }