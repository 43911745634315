import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SidenavMenu } from '../app/theme/components/sidenav-menu/sidenav-menu.model';
import { BouquteListStore, BouquteList, Category, Categories, Product, ProductDetails, ProductItem, cartlist, ResponseData } from './app.models';



export interface BouquetSize {
    nBouquetSizeId: any;
    cName: string;
    fPrice: any;
    fSpecialPrice: any;
    cImage: any;
    cDescription: any;
    nMainCount: any;
    nFillerCount: any;
    nLanguageId: any;
    nProductId: any;
    Currency: any;
}

export class Data {
    constructor(
        public ResponseData: ResponseData[],
        public categories: Category[],
        public BouquteMainLiat: BouquteList[],
        public BouquteFillerList: BouquteList[],
        public BouquteListStore: BouquteListStore[],
        public compareList: ProductDetails[],
        public wishList: ProductDetails[],
        public cartList: cartlist[],
        public totalPrice: number,
        public cUserName: string,
        public cUserImage: string,
        public cCustomerLoginType: string,
        public cBrandList: [],
        public totalCartCount: number,
        public cTypeBabyMumz: number,
        public router: Router,
        public cLableValues: number,

    ) { }
}

@Injectable()

export class AppService {

    public _BouquetSize = new BehaviorSubject<BouquetSize[]>([]);
    readonly _BouquetSizeStore = this._BouquetSize.asObservable();
    public Data = new Data(
        [],
        [], // categories
        [],//BouquteMainLiat
        [],//BouquteFillerList
        [],//BouquteListStore,
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        "",     //username
        "",      //userimage
        "",     //cCustomerLoginType
        [],
        0, //totalCartCount,
        1,
        null, //route,
        0 //lable values
    )

    public fbouquetsComponets: any;
    public slides: any;
    public brands: any;
    public IscheckOneTime = 0;
    public lstCategories: any;
    lstProductCart: cartlist[];
    public ischeckDliveryType = "lbl_New_checkout_DeliveryOption_pop_title";
    public isCheckDliveryDate = "lbl_New_checkout_DeliveryOption_pop_deliverydate";
    public isCheckDliveryTime = "lbl_New_checkout_DeliveryOption_pop_deliverytime";
    public url = "assets/data/";
    // public apiUrl="http://www.khaam.me/admin/webservices/";//For khaam
    // public apiUrl="http://localhost:60415/WebServices/";//Kitakeet local
    public apiUrl = "http://khaam.me/kitakeet/webservices/";//Kitakeet live

    //"http://localhost:63251/webservices/";//"http://localhost:63251/WebServices/";//
    //public urlTahani="https://tahaniflowers.live/admin/webservices/";
    //public urlTahani="http://localhost:55988/webservices/V1/";
    public urlTahani = "https://tahaniflowers.live/admin/webservices/V1/";
    //public urlTahani="https://khaam.me/tahaniadmin/webservices/V1/";
    public cTokenTahani = "C14PwvwO4jjejXPcphTF";
    public nUserId = "1";
    public nLanguageId: any;//localStorage.getItem("nLanguageId");
    public nCountryId: any;//localStorage.getItem("nLanguageId");
    public cTocken = "fCGiIPgXbJ1q9Axf4zuA";
    public nCustomerId: any;
    public nQuantity = 1;
    public flags = [];
    public Countrys = [];
    public flag: any;
    public Country: any;
    public CountryId: any;
    public subTotal: any;
    public SearchPath: any;
    public cDeviceName = 'web';
    public cAppVersion = '1';
    public ServiceCartArray = [];
    public auth2: any;
    public isCheckFiller: number = 0;
    public isLoginWith = 0;
    public cLabelValue = 0;

    //localStorage.setItem('_Type','Category');
    constructor(public translate: TranslateService, public http: HttpClient,
        public snackBar: MatSnackBar,
        //public writeFile:writeFile,
    ) { }




    public setLanguage() {

        var lang = localStorage.getItem("nLanguageId");
        if (lang == undefined) {
            localStorage.setItem("nLanguageId", "1");
            localStorage.setItem("DefaultLang", "en");
            this.nLanguageId = 1;
        }
        else {
            var nlang = localStorage.getItem("nLanguageId");
            this.nLanguageId = nlang;
        }
        var nCountryId = localStorage.getItem("nCountryId");
        if (nCountryId == undefined && nCountryId == null) {
            this.nCountryId = "1";//Kuwait            
        }
        else {
            this.nCountryId = localStorage.getItem("nCountryId");
        }
    }

    fbLibrary() {

        (window as any).fbAsyncInit = function () {
            window['FB'].init({
                appId: '1176614589814285',
                cookie: true,
                xfbml: true,
                version: 'v2.4'
            });
            window['FB'].AppEvents.logPageView();

        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

    //This funcation used to comman sign up         
    public UserSignUp(cCustomerName, cContactNo, EmailId, Password, cDeviceToken, cCustomerType, singuppagename) {

        let Ischecktrue = "0";
        this.AddCustomer(cCustomerName, cContactNo, EmailId, Password, cDeviceToken, cCustomerType).subscribe(data => {
            if (data[0].Success == '1') {

                localStorage.setItem("name", data[0].Result[0].cCustomerName);
                localStorage.setItem("phoneno", data[0].Result[0].cContactNo);
                this.Data.cUserName = data[0].Result[0].cCustomerName;
                localStorage.setItem("name", data[0].Result[0].cCustomerName);
                localStorage.setItem("CustomerImage", data[0].Result[0].cCustomerImage);
                localStorage.setItem('nCustomerId', data[0].Result[0].nCustomerId);
                var cMessage = "";
                this.translate.get('lbl_New_You_registered_successfully').subscribe((res: string) => {
                    cMessage = res;
                });
                Ischecktrue = "1";
                this.snackBar.open(cMessage, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                //this.Data.router.navigate(['/']); 
                if (singuppagename == "1") {
                    window.location.href = "/checkout/1";
                }
                if (singuppagename == "2") {
                    window.location.href = "/subscrip-order";
                }
                if (singuppagename == "0") {
                    window.location.href = "/";
                }

                //   location.reload();
            }
            else {
                Ischecktrue = "0";
                var message = data[0].Message;
                this.snackBar.open(message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
                return Ischecktrue;
            }

            //return Ischecktrue;
        });
        return Ischecktrue;
    }


    public GenerateDeviceToken() {


        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        const lengthOfCode = 40;
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    public getCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(this.url + 'categories.json');
    }



    public getProducts(type): Observable<Product[]> {
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getBestSeller(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'product.asmx/SelectHomeCategoryAndProduct', { params: param1 });
    }


    public getProductById(id): Observable<Product> {
        return this.http.get<Product>(this.urlTahani + 'product-' + id + '.json');
    }

    public getBanners(): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'banners.json');

        //return
    }



    public getCategory(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'category.asmx/SelectAllCategoryByLanguageId', { params: param1 });
    }



    public addToCompare(ProductDetails: ProductDetails) {
        let message, status;
        if (this.Data.compareList.filter(item => item.nProductId == ProductDetails.nProductId)[0]) {
            message = 'The product ' + ProductDetails.cName + ' already added to comparison list.';
            status = 'error';
        }
        else {
            this.Data.compareList.push(ProductDetails);
            message = 'The product ' + ProductDetails.cName + ' has been added to comparison list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public getWishlistByCustomerId(nCustomerId) {
        let cDeviceToken = localStorage.getItem('cDeviceToken');
        this.getWishlistByCustomer(nCustomerId).subscribe(data => {
            if (Boolean(data)) {
                if (data[0].Success == 1) {

                    this.Data.wishList.length = data[0].result.length;
                }
            }
        });
    }




    public getSlidesList() {
        this.GetAllSlider(this.Data.cTypeBabyMumz).subscribe(data => {

            var newParameters;
            //var newParameters;
            var datalist = [];
            for (var i = 0; i < data[0].result.length; i++) {


                newParameters = {};
                var nProductId = data[0].result[i].nProductId;
                var nCategoryId = data[0].result[i].nCategoryId;
                var title = data[0].result[i].cSliderName;
                var image = data[0].result[i].cSliderImage;
                var cProductName = data[0].result[i].cProductName;
                var cCategoryName = data[0].result[i].cCategoryName;
                newParameters = { nProductId: nProductId, nCategoryId: nCategoryId, title: title, image: image, cProductName: cProductName, cCategoryName: cCategoryName };
                datalist.push(newParameters);
                //this.slides = this.slides.concat(newParameters);     

            }
            // var csta="+["+newParameters+"]";
            this.slides = datalist;


        });
    }

    public getBrandsList() {
        var datalist = [];
        this.SelectAllBrand().subscribe(data => {
            var newParameters;
            if (data[0].Success == "1") {
                //  datalist=data[0].Result;           
                for (var i = 0; i < data[0].result.length; i++) {

                    newParameters = {};
                    var nBrandId = data[0].result[i].nBrandId;
                    var cBrandName = data[0].result[i].cBrandName;
                    var cBrandImage = data[0].result[i].cBrandImage;
                    newParameters = { nBrandId: nBrandId, cBrandImage: cBrandImage, cBrandName: cBrandName };

                    datalist.push(newParameters);
                }
            }


        });
        return datalist;
    }

    public AllBrandList() {
        this.GetAllBrandList().subscribe(data => {

            var newParameters;
            //var newParameters;
            var datalist = [];
            //this.brands=data[0].Result;
            if (data[0].success == "1") {
                for (var i = 0; i < data[0].Result.length; i++) {

                    newParameters = {};
                    var nBrandId = data[0].Result[i].nBrandId;
                    var cBrandName = data[0].Result[i].cBrandName;
                    var cBrandImage = data[0].Result[i].cBrandImage;
                    newParameters = { nBrandId: nBrandId, cBrandName: cBrandName, cBrandImage: cBrandImage };

                    datalist.push(newParameters);
                }
                this.brands = datalist;
            }

        });
    }



    public cLanguageName: any;
    public getAllLanguage() {

        this.SelectAllLanguage().subscribe(data => {

            if (data[0].Success == "1") {
                var newParameters;
                var datalist = [];
                for (var i = 0; i < data[0].result.length; i++) {

                    newParameters = {};
                    var nLanguageId = data[0].result[i].nLanguageId;
                    var name = data[0].result[i].cLanguageName;
                    var cLanguageCssType = data[0].result[i].cLanguageCssType;
                    newParameters = {
                        nLanguageId: nLanguageId,
                        name: name,
                        cLanguageCssType: cLanguageCssType
                    };
                    datalist.push(newParameters);
                    //this.slides = this.slides.concat(newParameters);     

                }
            }

            // var csta="+["+newParameters+"]";
            this.flags = datalist;
            this.flag = this.flags[0];
            this.cLanguageName = data[0].result[0].cLanguageName;
            //this.translate.addLangs(['en', 'ar']);  
            var cCountryId = localStorage.getItem("nCountryId");
            if (cCountryId == "1") {
                // this.translate.addLangs(['en', 'ar']); 
                this.translate.setDefaultLang('en');
            }
            else {
                this.translate.addLangs(['UAEen', 'UAEar']);
                this.translate.setDefaultLang('en');
            }

            this.translate.setDefaultLang(localStorage.getItem("DefaultLang"));


        });
    }

    public getAllCountry() {

        this.SelectAllCountryByLanguageId().subscribe(data => {

            if (data[0].Success == "1") {
                var newParameters;
                var datalist = [];
                for (var i = 0; i < data[0].Result.length; i++) {

                    newParameters = {};
                    var nCountryId = data[0].Result[i].nCountryId;
                    var name = data[0].Result[i].cCountryName;
                    newParameters = {
                        nCountryId: nCountryId,
                        name: name
                    };
                    datalist.push(newParameters);
                    //this.slides = this.slides.concat(newParameters); 
                }
            }
            // var csta="+["+newParameters+"]";
            this.Countrys = datalist;
            this.Country = this.Countrys[0].cCountryName;
            this.CountryId = this.Countrys[0].nCountryId
        });
    }

    public getcurrency() {
        this.SelectCountryWiseCurrency().subscribe(data => {
            if (data[0].cCurrencySymbol != undefined && data[0].cCurrencySymbol != "" && data[0].cCurrencySymbol != null) {
                localStorage.setItem("cCurrencySymbol", data[0].cCurrencySymbol);
            }
            else {
                localStorage.setItem("cCurrencySymbol", "KWD");
            }
        });
    }





    public postId: any;
    public __dirname = "assets/i18n/en.json";


    public addToWishList(ProductDetails: ProductDetails) {
        let message, status;
        if (this.Data.wishList.filter(item => item.nProductId == ProductDetails.nProductId)[0]) {
            message = 'The product ' + ProductDetails.cName + ' already added to wish list.';
            status = 'error';
        }
        else {
            this.Data.wishList.push(ProductDetails);
            message = 'The product ' + ProductDetails.cName + ' has been added to wish list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToCart(ProductDetails: ProductDetails) {

        let message, status;
        let nQuantity = 1;
        this.nQuantity = 1;
        let nCartType = 1;
        let _Type = localStorage.getItem('_Type');
        let nSupplierId = 0;

        if (_Type == 'Category')
            nSupplierId = ProductDetails.nSupplierId;
        else
            nSupplierId = Number.parseInt(localStorage.getItem('nSupplierId'));

        let cDeviceToken = localStorage.getItem('cDeviceToken');
        if (!cDeviceToken) {
            cDeviceToken = this.GenerateDeviceToken();
            localStorage.setItem("cDeviceToken", cDeviceToken);
        }

        let nCustomerIdPass = 0;
        if (localStorage.getItem('nCustomerId')) {
            if (Number.parseInt(localStorage.getItem('nCustomerId')) > 0) {
                nCustomerIdPass = Number.parseInt(localStorage.getItem('nCustomerId'));
                cDeviceToken = "";
            }
        }

        this.addCartData(nCustomerIdPass, ProductDetails.nProductId, nSupplierId, nQuantity, nCartType, cDeviceToken, 0).subscribe(data => {

            if (data[0].Success == '1') {
                message = 'The product ' + ProductDetails.cName + ' has been added to cart.';  //data[0].Message; 
                status = 'success';

                this.getCart();
            }
            else {

                message = 'The product ' + ProductDetails.cName + ' already added to cart.';
                status = 'error';
            }
            this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });


        });




    }

    public GetLabelName(cLabelValue) {
        this.GetLanguageDataByLabelName(cLabelValue).subscribe(data => {
            if (Boolean(data)) {
                if (data[0].Success == 1) {
                    this.Data.cLableValues = data[0].result[0].Table[0].cLabelValue;
                    localStorage.setItem("cLableValues", data[0].result[0].Table[0].cLabelValue);
                }
            }
        });
    }

    public updateToCart(cartdata) {

        let message, status;
        let nQuantity = cartdata.soldQuantity;
        let nCartType = 1;
        let nSupplierId = 0;
        nSupplierId = Number.parseInt(cartdata.nBrandMomId);

        let cDeviceToken = localStorage.getItem('cDeviceToken');

        let nCustomerIdPass = 0;
        if (localStorage.getItem('nCustomerId')) {
            if (Number.parseInt(localStorage.getItem('nCustomerId')) > 0) {
                nCustomerIdPass = Number.parseInt(localStorage.getItem('nCustomerId'));
                cDeviceToken = "";
            }
        }

        let isCall = false;
        if (cDeviceToken) {
            isCall = true;
        }
        if (nCustomerIdPass > 0) {
            isCall = true;
        }
        if (isCall) {

            this.addCartData(nCustomerIdPass, cartdata.productId, nSupplierId, nQuantity, nCartType, cDeviceToken, cartdata.ncartid).subscribe(data => {

                if (data[0].Success == '1') {
                    message = 'The product has been added to cart.';  //data[0].Message; 
                    status = 'success';

                    return 1;
                }
                else {

                    message = 'The product already added to cart.';
                    status = 'error';

                    return 0;
                }


            });
        }
        else {
            return 0;
        }
    }

    public getCart() {

        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;

        let cDeviceToken = localStorage.getItem('cDeviceToken');

        let nCustomerIdPass = 0;
        if (localStorage.getItem('nCustomerId')) {
            if (Number.parseInt(localStorage.getItem('nCustomerId')) > 0) {
                nCustomerIdPass = Number.parseInt(localStorage.getItem('nCustomerId'));
                cDeviceToken = "";
            }
        }

        let isCall = false;
        if (cDeviceToken) {
            isCall = true;
        }
        if (nCustomerIdPass > 0) {
            isCall = true;
        }

        if (isCall) {

            this.SelectAllCartProductByCustomerV2(nCustomerIdPass, cDeviceToken, 1).subscribe(data => {
                if (data[0].Success == '1') {
                    this.Data.cartList = data[0].Result;

                    if (data[0].Result.length > 0) {
                        this.Data.totalPrice = data[0].fSubTotal;
                        this.subTotal = data[0].fSubTotal;
                        this.Data.totalCartCount = data[0].Result.length;
                    }


                }
                else {

                }
            });
        }
        else {

        }
    }



    public resetProductCartCount(ProductDetails: ProductDetails) {
        ProductDetails.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item => item.nProductId == ProductDetails.nProductId)[0];
        if (compareProduct) {
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item => item.nProductId == ProductDetails.nProductId)[0];
        if (wishProduct) {
            wishProduct.cartCount = 0;
        };
    }





    public getMonths() {
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears() {
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
    }


    ///-----------------------new development section ------------------------------------------------------

    //To get all vendors list
    addData(data) {
        let user = [];
        if (localStorage.getItem('User')) {
            user = JSON.parse(localStorage.getItem('User'));
            user = [user, data];
        }
        else {
            user = [data];
        }
        localStorage.setItem('User', JSON.stringify(data));
    }



    // public sidenavMenuItems = [];
    // public menuItems=[];
    // public parentMenu=[];
    // public menuParentId=[];
    // public getSidenavMenuItems()
    // {
    //     var Home = 'Home'; 
    //       this.translate.get('lbl_New_side_menu_home').subscribe(( res: string ) => 
    //       {
    //         Home=res;
    //       }); 
    //       var AboutUs="About Us"
    //       this.translate.get('lbl_New_aboutus_nav_title').subscribe(( res: string ) => 
    //       {
    //         AboutUs=res;
    //       }); 
    //     var Video="Video"
    //       this.translate.get('lbl_New_video_nav_title').subscribe(( res: string ) => 
    //       {
    //         Video=res;
    //       }); 
    //     var ContactUs="Contact Us"
    //     this.translate.get('lbl_New_contactus_btn_save').subscribe(( res: string ) => 
    //     {
    //       ContactUs=res;
    //     }); 

    //         this.sidenavMenuItems.push(new SidenavMenu (1, Home, '/', null, null, false, 0))
    //         this.sidenavMenuItems.push(new SidenavMenu (2, AboutUs, '/about-us', null, null, false, 0))
    //     this.GetMainandSubCategory().subscribe(data => 
    //         {
    //           if(data[0].Success=="1")
    //           {        

    //               for (let i = 0; i < data[0].result.length; i++) 
    //               {

    //                   var cCategoryName=data[0].result[i].cCategoryName;
    //                   var nCategoryId=data[0].result[i].nCategoryId;

    //                    if(data[0].result[i].SubCategory !=null)
    //                     {
    //                       if(data[0].result[i].SubCategory !="[]")
    //                       {
    //                         this.sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, null, null, null, true,0))
    //                       }
    //                       else
    //                       {
    //                         this.sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, 'products/'+nCategoryId+'/Category', null, null, false,0))
    //                       }
    //                     }
    //                     else
    //                     {
    //                         this.sidenavMenuItems.push(new SidenavMenu (nCategoryId, cCategoryName, 'products/'+nCategoryId+'/Category', null, null, false,0))
    //                     }



    //                  if(data[0].result[i].SubCategory !=null)
    //                    {
    //                      for (let j = 0; j < data[0].result[i].SubCategory.length; j++) 
    //                       {
    //                         var SubCategoryName=data[0].result[i].SubCategory[j].cCategoryName;
    //                         var nSubCategoryId=data[0].result[i].SubCategory[j].nCategoryId;
    //                         this.sidenavMenuItems.push(new SidenavMenu (nSubCategoryId, SubCategoryName, 'products/'+nSubCategoryId+'/Category', null, null, false, nCategoryId))
    //                       }


    //                   }
    //               }
    //               var Video="Video"
    //             this.translate.get('lbl_New_video_nav_title').subscribe(( res: string ) => 
    //             {
    //                 Video=res;
    //             }); 
    //             var ContactUs="Contact Us"
    //             this.translate.get('lbl_New_contactus_btn_save').subscribe(( res: string ) => 
    //             {
    //             ContactUs=res;
    //             }); 
    //               this.sidenavMenuItems.push(new SidenavMenu (3, Video, '/Categories/video', null, null, false, 0))
    //               this.sidenavMenuItems.push(new SidenavMenu (4, ContactUs, '/contact', null, null, false, 0))
    //               this.menuItems=this.sidenavMenuItems;
    //               this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    //            }
    //         });
    // }



    public attachSignin(element) {
        this.auth2.attachClickHandler(element, {},
            (googleUser) => {
                let profile = googleUser.getBasicProfile();
                let cCustomerType = 'Google';
                this.Data.cCustomerLoginType = "Google";
                let cCustomerName = profile.getName();
                let cContactNo = '';
                let cEmail = profile.getEmail();
                let Password = profile.getId();
                let cDeviceToken = ''
                this.AddCustomer(cCustomerName, cContactNo, cEmail, Password, cDeviceToken, cCustomerType).subscribe(data => {
                    if (data[0].Success == '1') {

                        localStorage.setItem("name", data[0].Result[0].cCustomerName);
                        localStorage.setItem("phoneno", data[0].Result[0].cContactNo);
                        this.Data.cUserName = data[0].Result[0].cCustomerName;
                        localStorage.setItem("name", data[0].Result[0].cCustomerName);
                        localStorage.setItem("CustomerImage", data[0].Result[0].cCustomerImage);
                        localStorage.setItem('nCustomerId', data[0].Result[0].nCustomerId);
                        var cMessage = "";
                        this.translate.get('lbl_New_You_registered_successfully').subscribe((res: string) => {
                            cMessage = res;
                        });
                        // this.router.navigate(['/']); 

                    }
                    else {

                        var message = data[0].Message;
                        this.snackBar.open(message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

                    }


                });

            }, (error) => {
            alert(JSON.stringify(error, undefined, 2));
        });
    }


    public getVendorList(nTypebabyMumz): Observable<any> {


        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);

        return this.http.get<any>(this.urlTahani + 'customer.asmx/SelectAllSupplier', { params: param1 });
    }

    public SelectHomeCategoryAndProduct(nTypebabyMumz): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTocken)
            .set('nLanguageId', this.nLanguageId)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'product.asmx/SelectHomeCategoryAndProduct', { params: param1 });
    }

    public getMomsList(nTypebabyMumz): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTocken)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'customer.asmx/SelectAllMoms', { params: param1 });
    }

    public getCategoriesList(nTypebabyMumz): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);

        return this.http.get<any>(this.urlTahani + 'Category.asmx/SelectAllCategoryByLanguageId', { params: param1 });
    }


    public getSubCategoryListByParentCategoryId(nCategoryId, nTypebabyMumz): Observable<Product> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nParentCategoryId', nCategoryId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<Product>(this.urlTahani + 'Category.asmx/SelectAllSubCategoryByparentIdforweb', { params: param1 });
    }




    public getProductListByVendorId(nCustomerId, nTypebabyMumz): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nVendorId', nCustomerId)
            .set('nCategoryId', '0')
            .set('nLanguageId', this.nLanguageId)
            .set('nFromId', '1')
            .set('nToId', '12')
            .set('cToken', this.cTocken)
            .set('nType', nTypebabyMumz)
            .set('cFilterType', '')
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Product.asmx/SelectAllVendorProduct', { params: param1 });
    }

    public getProductListByMomsId(nCustomerId, nTypebabyMumz): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nVendorId', nCustomerId)
            .set('nCategoryId', '0')
            .set('nLanguageId', this.nLanguageId)
            .set('nFromId', '1')
            .set('nToId', '12')
            .set('cToken', this.cTocken)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Product.asmx/SelectAllMomsProductV1', { params: param1 });
    }

    public GetAllSlider(nTypebabyMumz): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Slider.asmx/SelectAllSliderV2', { params: param1 });
    }

    public SelectAllLanguage(): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<ProductItem>(this.urlTahani + 'language.asmx/SelectAllLanguage', { params: param1 });
    }

    public getProductListByCategoryId(nCategoryId, nFromId, nToId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCategoryId', nCategoryId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cFilterType', '1')
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/SelectAllProductByCategory', { params: param1 });
    }

    public SelectAllProductByCategoryUsingweb(nCategoryId, nFromId, nToId, cSerchProductName): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCategoryId', nCategoryId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cFilterType', '1')
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('cSerchProductName', cSerchProductName)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/SelectAllProductByCategoryUsingweb', { params: param1 });
    }


    public AddCustomer(cCustomerFirstName, cCustomerContactNo, cCustomerEmailId, cPassword, cDeviceToken, cCustomerType): Observable<any> {
        let param1 = new HttpParams().set('cCustomerFirstName', cCustomerFirstName)
            .set('cCustomerLastName', '')
            .set('cCustomerContactNo', cCustomerContactNo)
            .set('cCustomerEmailId', cCustomerEmailId)
            .set('nUserId', this.nUserId)
            .set('cPassword', cPassword)
            .set('cGender', '')
            .set('cCustomerType', cCustomerType)
            .set('cIPAddress', '')
            .set('cMType', '')
            .set('cToken', this.cTokenTahani)
            .set('cDeviceToken', cDeviceToken)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Customer.asmx/AddCustomer', { params: param1 });
    }


    public UpdateCustomerProfile(nCustomerId, cCustomerFirstName, cCustomerLastName, cCustomerContactNo, cCustomerEmailId, cCustomerImage, cBio): Observable<any> {
        let param1 = new HttpParams().set('nCustomerId', nCustomerId)
            .set('cCustomerFirstName', cCustomerFirstName)
            .set('cCustomerLastName', cCustomerLastName)
            .set('cCustomerContactNo', cCustomerContactNo)
            .set('cCustomerEmailId', cCustomerEmailId)
            .set('nUserId', this.nUserId)
            .set('cGender', '')
            .set('cCustomerImage', cCustomerImage)
            .set('cBio', cBio)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Customer.asmx/UpdateCustomerProfile', { params: param1 });
    }



    public uploadfile(FileName): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('FileType', 'Customer')
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('File', FileName)
            .set('nCountryId', this.nCountryId)
        return this.http.post<any>(this.urlTahani + 'fileupload.asmx/uploadfile', { params: param1 });
    }


    public AddImage(objWishlist) {
        const headers = { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        const body = JSON.stringify(objWishlist);


        this.http.post(this.urlTahani + 'fileupload.asmx/uploadfile', body).subscribe(

        )

    }

    public uploadImage(img: File): Observable<any> {
        const form = new FormData;

        form.append('nUserId', this.nUserId);
        form.append('FileType', 'Customer');
        form.append('nLanguageId', this.nLanguageId);
        form.append('cToken', this.cTokenTahani),
            form.append('File', img);
        return this.http.post(this.urlTahani + 'fileupload.asmx/uploadfile', form);

    }

    public getSesstion(): Observable<any> {
        const form = new FormData;
        form.append("CustomerIdentifier", "123");
        const headers = { "Access-Control-Allow-Origin": "https://tahaniflowers.live/", 'Authorization': 'Bearer rLtt6JWvbUHDDhsZnfpAhpYk4dxYDQkbcPTyGaKp2TYqQgG7FGZ5Th_WD53Oq8Ebz6A53njUoo1w3pjU1D4vs_ZMqFiz_j0urb_BH9Oq9VZoKFoJEDAbRZepGcQanImyYrry7Kt6MnMdgfG5jn4HngWoRdKduNNyP4kzcp3mRv7x00ahkm9LAK7ZRieg7k1PDAnBIOG3EyVSJ5kK4WLMvYr7sCwHbHcu4A5WwelxYK0GMJy37bNAarSJDFQsJ2ZvJjvMDmfWwDVFEVe_5tOomfVNt6bOg9mexbGjMrnHBnKnZR1vQbBtQieDlQepzTZMuQrSuKn-t5XZM7V6fCW7oP-uXGX-sMOajeX65JOf6XVpk29DP6ro8WTAflCDANC193yof8-f5_EYY-3hXhJj7RBXmizDpneEQDSaSz5sFk0sV5qPcARJ9zGG73vuGFyenjPPmtDtXtpx35A-BVcOSBYVIWe9kndG3nclfefjKEuZ3m4jL9Gg1h2JBvmXSMYiZtp9MR5I6pvbvylU_PP5xJFSjVTIz7IQSjcVGO41npnwIxRXNRxFOdIUHn0tjQ-7LwvEcTXyPsHXcMD8WtgBh-wxR8aKX7WPSsT1O8d8reb2aR7K3rkV3K82K_0OgawImEpwSvp9MNKynEAJQS6ZHe_J_l77652xwPNxMRTMASk1ZsJL' };
        const body = form;
        return this.http.post('http://apitest.myfatoorah.com/v2/InitiateSession', body, { headers });

    }


    addPerson(person): Observable<any> {
        const headers = { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        const body = JSON.stringify(person);

        return this.http.post(this.urlTahani + 'fileupload.asmx/uploadfile', body, { 'headers': headers })
    }


    public SelectAllCityByLanguageIdAndCountryId(): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', '1')
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Country.asmx/SelectAllCityByLanguageIdAndCountryId', { params: param1 });
    }

    public SelectAddressDetail(nAddressId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nAddressId', nAddressId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Address.asmx/SelectAddressDetail', { params: param1 });
    }

    public getdeliverytime(): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'deliverytime.asmx/GetTimeSlotV1', { params: param1 });
    }

    public GetTimeSlotV2(nTimeSlotType): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nTimeSlotType', nTimeSlotType)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'deliverytime.asmx/GetTimeSlotV2', { params: param1 });
    }

    public SelectAllAddressByCustomer(CustomerId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nCustomerId', CustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Address.asmx/SelectAllAddressByCustomer', { params: param1 });
    }


    public DeleteAddress(nAddressId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nAddressId', nAddressId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Address.asmx/DeleteAddress', { params: param1 });
    }



    public SelectAllAreaByLanguageIdAndCityId(nCityId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCityId', nCityId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Country.asmx/SelectAllAreaByLanguageIdAndCityId', { params: param1 });
    }

    public getguestuserdetails(cUserName): Observable<any> {
        let param1 = new HttpParams().set('cUserName', cUserName)
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'login.asmx/getguestuserdetails', { params: param1 });
    }




    public LogIn_User(cUserName, cPassword, cDeviceToken): Observable<any> {
        let param1 = new HttpParams().set('cUserName', cUserName)
            .set('cPassword', cPassword)
            .set('nUserId', this.nUserId)
            .set('cPassword', cPassword)
            .set('cCustomerType', 'Customer')
            .set('cIPAddress', '')
            .set('cMType', '')
            .set('cToken', this.cTokenTahani)
            .set('cDeviceToken', cDeviceToken)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'login.asmx/LogIn_User', { params: param1 });
    }

    public PlaceOrder(nCustomerId, fTotal, cPaymentTerms, dtDelivaryDate, cAddress, cCountryCode, cTime, cCouponCode, cJsonData, cOrderNotes, cAddressSpecialInstructions,
        fDeliveryCharge, cBlock, cStreet, cAvenue, cHouse, cFloor, cBuildingNo, cName, cMobileNo, cAlterMobileNo, cGovernorate, cArea, cSpecialInstruction,
        fCouponDiscount, cFrom, cTo, cLinkGiftCard, cMessage, cAddressType, cLandmark, cBuildingName, cOfficeName): Observable<any> {

        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustId', nCustomerId)
            .set('fTotal', fTotal)
            .set('cPaymentTerms', cPaymentTerms)//pass moms id if product selected via mom else brands id
            .set('dtDelivaryDate', dtDelivaryDate)
            .set('cAddress', cAddress)
            .set('cCountryCode', cCountryCode)
            .set('cTime', cTime)
            .set('cCouponCode', cCouponCode)
            .set('cJsonData', cJsonData)
            .set('cOrderNotes', cOrderNotes)
            .set('cAddressSpecialInstructions', cAddressSpecialInstructions)
            .set('fDeliveryCharge', fDeliveryCharge)
            .set('cBlock', cBlock)
            .set('cStreet', cStreet)
            .set('cAvenue', cAvenue)
            .set('cHouse', cHouse)
            .set('cFloor', cFloor)
            .set('cBuildingNo', cBuildingNo)
            .set('cName', cName)
            .set('cMobileNo', cMobileNo)
            .set('cAlterMobileNo', cAlterMobileNo)
            .set('cGovernorate', cGovernorate)
            .set('cArea', cArea)
            .set('cSpecialInstruction', cSpecialInstruction)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('cOrderType', 'Admin')
            .set('fCouponDiscount', fCouponDiscount)
            .set('cFrom', cFrom)
            .set('cTo', cTo)
            .set('cLinkGiftCard', cLinkGiftCard)
            .set('cMessage', cMessage)
            .set('nCountryId', this.nCountryId);

        return this.http.get<any>(this.urlTahani + 'order.asmx/CreateOrderWithJsonDataV3', { params: param1 });
    }

    public CreateOrderWithJsonDataV4(nCustId, fTotal, cPaymentTerms, dtDelivaryDate, cAddress, cCountryCode
        , cTime, cCouponCode, cJsonData, cOrderNotes, cAddressSpecialInstructions, fDeliveryCharge, cBlock, cStreet, cAvenue, cHouse
        , cFloor, cBuildingNo, cName, cMobileNo, cAlterMobileNo, cGovernorate, cArea, cSpecialInstruction,
        cOrderType, fCouponDiscount, cFrom, cTo, cLinkGiftCard, cMessage, nBouquetSizeId, nBouquetTypeId): Observable<any> {
        const form = new FormData;
        form.append('nUserId', this.nUserId),
            form.append('nCustId', nCustId),
            form.append('fTotal', fTotal),
            form.append('cPaymentTerms', cPaymentTerms),
            form.append('dtDelivaryDate', dtDelivaryDate),
            form.append('cAddress', cAddress),
            form.append('cCountryCode', cCountryCode),
            form.append('cTime', cTime)
        form.append('cCouponCode', cCouponCode)
        form.append('cJsonData', cJsonData)
        form.append('cOrderNotes', cOrderNotes)
        form.append('cAddressSpecialInstructions', cAddressSpecialInstructions)
        form.append('fDeliveryCharge', fDeliveryCharge)
        form.append('cToken', this.cTokenTahani)
        form.append('cBlock', cBlock)
        form.append('cStreet', cStreet)
        form.append('cAvenue', cAvenue)
        form.append('cHouse', cHouse)
        form.append('cFloor', cFloor)
        form.append('cBuildingNo', cBuildingNo)
        form.append('cName', cName)
        form.append('cMobileNo', cMobileNo)
        form.append('cAlterMobileNo', cAlterMobileNo)
        form.append('cGovernorate', cGovernorate)
        form.append('cArea', cArea)
        form.append('cSpecialInstruction', cSpecialInstruction)
        form.append('nLanguageId', this.nLanguageId)
        form.append('cOrderType', cOrderType)
        form.append('fCouponDiscount', fCouponDiscount)
        form.append('cFrom', cFrom)
        form.append('cTo', cTo)
        form.append('cLinkGiftCard', cLinkGiftCard)
        form.append('cMessage', cMessage)
        form.append('nBouquetSizeId', nBouquetSizeId)
        form.append('nBouquetTypeId', nBouquetTypeId)
        form.append('cDeviceName', this.cDeviceName)
        form.append('cAppVersion', this.cAppVersion)
        form.append('nCountryId', this.nCountryId)
        return this.http.post(this.urlTahani + '/order.asmx/CreateOrderWithJsonDataV4', form);
    }


    public CreateOrderWithJsonDataV6(nCustId, fTotal, cPaymentTerms, dtDelivaryDate, cAddress, cCountryCode
        , cTime, cCouponCode, cJsonData, cOrderNotes, cAddressSpecialInstructions, fDeliveryCharge, cBlock, cStreet, cAvenue, cHouse
        , cFloor, cBuildingNo, cName, cMobileNo, cAlterMobileNo, cGovernorate, cArea, cSpecialInstruction,
        cOrderType, fCouponDiscount, cFrom, cTo, cLinkGiftCard, cMessage, nBouquetSizeId, nBouquetTypeId,
        nDeliveryTimeType, nStoreId, IsCredit, fWalletAmount): Observable<any> {
        const form1 = new FormData;
        form1.append('nUserId', this.nUserId),
        form1.append('nCustId', nCustId),
        form1.append('fTotal', fTotal),
        form1.append('cPaymentTerms', cPaymentTerms),
        form1.append('dtDelivaryDate', dtDelivaryDate),
        form1.append('cAddress', cAddress),
        form1.append('cCountryCode', cCountryCode),
        form1.append('cTime', cTime)
        form1.append('cCouponCode', cCouponCode)
        form1.append('cJsonData', cJsonData)
        form1.append('cOrderNotes', cOrderNotes)
        form1.append('cAddressSpecialInstructions', cAddressSpecialInstructions)
        form1.append('fDeliveryCharge', fDeliveryCharge)
        form1.append('cToken', this.cTokenTahani)
        form1.append('cBlock', cBlock)
        form1.append('cStreet', cStreet)
        form1.append('cAvenue', cAvenue)
        form1.append('cHouse', cHouse)
        form1.append('cFloor', cFloor)
        form1.append('cBuildingNo', cBuildingNo)
        form1.append('cName', cName)
        form1.append('cMobileNo', cMobileNo)
        form1.append('cAlterMobileNo', cAlterMobileNo)
        form1.append('cGovernorate', cGovernorate)
        form1.append('cArea', cArea)
        form1.append('cSpecialInstruction', cSpecialInstruction)
        form1.append('nLanguageId', this.nLanguageId)
        form1.append('cOrderType', cOrderType)
        form1.append('fCouponDiscount', fCouponDiscount)
        form1.append('cFrom', cFrom)
        form1.append('cTo', cTo)
        form1.append('cLinkGiftCard', cLinkGiftCard)
        form1.append('cMessage', cMessage)
        form1.append('nBouquetSizeId', nBouquetSizeId)
        form1.append('nBouquetTypeId', nBouquetTypeId)
        form1.append('cDeviceName', this.cDeviceName)
        form1.append('cAppVersion', this.cAppVersion)
        form1.append('nDeliveryTimeType', nDeliveryTimeType)
        form1.append('nStoreId', nStoreId)
        form1.append('IsCredit', IsCredit)
        form1.append('fWalletAmount', fWalletAmount)
        form1.append('nCountryId', this.nCountryId)
        return this.http.post(this.urlTahani + 'order.asmx/CreateOrderWithJsonDataV6', form1);
    }

    
    public AddAddress(cName, cBlock, cStreet, cAvenue, cHouse
        , cFloor, cBuildingNuber, cAddress, cMobileNo, cAlternateMobileNo, Governorate, Area, nCustomerId,
        cSpecialInstruction): Observable<any> {
        let param1 = new HttpParams().set('cName', cName)
            .set('cBlock', cBlock)
            .set('cStreet', cStreet)
            .set('cAvenue', cAvenue)
            .set('cHouse', cHouse)
            .set('cFloor', cFloor)
            .set('cBuildingNuber', cBuildingNuber)
            .set('cAddress', cAddress)
            .set('cMobileNo', cMobileNo)
            .set('cAlternateMobileNo', cAlternateMobileNo)
            .set('Governorate', Governorate)
            .set('Area', Area)
            .set('nCustomerId', nCustomerId)
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cSpecialInstruction', cSpecialInstruction)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'address.asmx/AddAddressV2', { params: param1 });
    }


    public UpdateAddress(nAddressId, cName, cBlock, cStreet, cAvenue, cHouse
        , cFloor, cBuildingNuber, cAddress, cMobileNo, cAlternateMobileNo, Governorate, Area, nCustomerId,
        cSpecialInstruction): Observable<any> {
        let param1 = new HttpParams()
            .set('nAddressId', nAddressId)
            .set('cName', cName)
            .set('cBlock', cBlock)
            .set('cStreet', cStreet)
            .set('cAvenue', cAvenue)
            .set('cHouse', cHouse)
            .set('cFloor', cFloor)
            .set('cBuildingNuber', cBuildingNuber)
            .set('cAddress', cAddress)
            .set('cMobileNo', cMobileNo)
            .set('cAlternateMobileNo', cAlternateMobileNo)
            .set('Governorate', Governorate)
            .set('Area', Area)
            .set('nCustomerId', nCustomerId)
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cSpecialInstruction', cSpecialInstruction)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'address.asmx/UpdateAddressV2', { params: param1 });
    }


    public SelectAllFeatureProduct(nTypebabyMumz): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTocken)
            .set('nLanguageId', this.nLanguageId)
            .set('nType', nTypebabyMumz)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.apiUrl + 'product.asmx/SelectAllFeatureProduct ', { params: param1 });
    }

    public getProductDetails(): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nProductId', '3')
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTocken)
            .set('nCustomerId', '0')
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.apiUrl + 'Product.asmx/Select_Product_Details', { params: param1 });
    }



    public GetMainandSubCategory(): Observable<Categories> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<Categories>(this.urlTahani + 'Category.asmx/SelectMainAndSubCategory', { params: param1 });
    }





    public Select_Product_DetailsV3(id, nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nProductId', id)
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCustomerId', nCustomerId)
            .set('cDeviceId', '')
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/Select_Product_DetailsV3', { params: param1 });
    }

    public Select_Product_DetailsByProductName(cProductName, nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('cProductName', cProductName)
            .set('nLanguageId', this.nLanguageId)
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCustomerId', nCustomerId)
            .set('cDeviceId', '')
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/Select_Product_DetailsByProductName', { params: param1 });
    }


    public getCustomerDetailsById(nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani);
        return this.http.get<ProductItem>(this.urlTahani + 'customer.asmx/SelectRow', { params: param1 });
    }



    public getCategoryListByVendorId(nCustomerId): Observable<Product> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nVendorId', nCustomerId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTocken)
            .set('nCountryId', this.nCountryId);
        return this.http.get<Product>(this.apiUrl + 'Category.asmx/GetVendorProductWiseCategoryList', { params: param1 });
    }


    public wishlist

    public addWishlistData(nCustomerId, nProductId, nSupplierId): Observable<any> {

        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nProductId', nProductId)
            .set('nCustomerId', nCustomerId)
            .set('cWishList', '1')
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Wishlist.asmx/InsertData', { params: param1 });
    }

    public getWishlistByCustomer(nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/SelectAllWishlistProduct', { params: param1 });
    }

    public removeWishlistData(nCustomerId, nProductId): Observable<any> {

        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nProductId', nProductId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);

        return this.http.get<any>(this.urlTahani + 'Wishlist.asmx/DeleteRowByCustomerIdandProductId', { params: param1 });
    }

    public removeAllWishlistData(nCustomerId): Observable<any> {

        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);

        return this.http.get<any>(this.urlTahani + 'Wishlist.asmx/DeleteRowByCustomerIdandProductId', { params: param1 });
    }

    public getProductReview(id): Observable<Product> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nProductId', id)
            .set('nLanguageId', this.nLanguageId)
            .set('nCustomerId', '0')
            .set('nFromId', '1')
            .set('nToId', '10')
            .set('cToken', this.cTocken)
            .set('nCountryId', this.nCountryId);
        return this.http.get<Product>(this.apiUrl + 'Reviews.asmx/SelectProductReview', { params: param1 });
    }

    public addCartData(nCustomerId, nProductId, nSupplierId, nQuantity, nCartType, cDeviceToken, nCartId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nProductId', nProductId)
            .set('nCustomerId', nCustomerId)
            .set('dtCartDate', new Date().toISOString())
            .set('nQuantity', nQuantity)
            .set('cDeviceToken', cDeviceToken)
            .set('nCartId', nCartId)
            .set('nCartType', nCartType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Cart.asmx/CreateCart', { params: param1 });
    }

    public CreateCartV2(nCustomerId, nProductId, nQuantity, nCartType, cDeviceToken, nCartId, nBouquetTypeId, nBouquetSizeId, cJsonServicesProducts, IsParent): Observable<any> {
        let param1 = new HttpParams()
            .set('nProductId', nProductId)
            .set('nCustomerId', nCustomerId)
            .set('nUserId', this.nUserId)
            .set('dtCartDate', new Date().toISOString())
            .set('nQuantity', nQuantity)
            .set('cToken', this.cTokenTahani)
            .set('cDeviceToken', cDeviceToken)
            .set('nCartId', nCartId)
            .set('nCartType', nCartType)
            .set('IsParent', IsParent)
            .set('nBouquetTypeId', nBouquetTypeId)
            .set('nBouquetSizeId', nBouquetSizeId)
            .set('cJsonServicesProducts', cJsonServicesProducts)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Cart.asmx/CreateCartV2', { params: param1 });
    }

    public getCartByCustomer(nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/SelectAllCartProductByCustomerV1', { params: param1 });
    }

    //Added new to refresh cart count when order placed(14-09-2021)
    public GetCartCountbyCustomer(nCustomerId, cDeviceToken): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cDeviceId', cDeviceToken)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/CartCountbyCustomer', { params: param1 });
    }

    public SelectAllCartProductByCustomerV2(nCustomerId, cDeviceToken, nCartType): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cDeviceId', cDeviceToken)
            .set('nCartType', nCartType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/SelectAllCartProductByCustomerV2', { params: param1 });
    }


    public SelectAllCartProductByCustomer(nCustomerId, cDeviceToken, nCartType): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cDeviceId', cDeviceToken)
            .set('nCartType', nCartType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/SelectAllCartProductByCustomerV2', { params: param1 });
    }

    public DeleteCartRecord(nCartId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCartId', nCartId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/DeleteCartRecord', { params: param1 });
    }



    public DeleteCartRecordV1(nCartId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCartId', nCartId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/DeleteCartRecordV1', { params: param1 });
    }

    public ClearCartByCustomer(nCustomerId, nCartType): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('nCartType', nCartType)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/ClearCartByCustomer', { params: param1 });
    }





    public AddCouponCode(cCouponCode, nCustomerId, cDeviceId, dtdate, cJsonCartData, nCartType, nAddressId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cCouponCode', cCouponCode)
            .set('nCustomerId', nCustomerId)
            .set('cDeviceId', cDeviceId)
            .set('dtdate', dtdate)
            .set('cJsonCartData', cJsonCartData)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCartType', nCartType)
            .set('nAddressId', nAddressId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'offer.asmx/CheckCouponCodeNew', { params: param1 });
    }


    public CheckCouponCodeNewV1(cCouponCode, nCustomerId, cDeviceId, dtdate, cJsonCartData, nCartType, nAddressId, nStoreId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cCouponCode', cCouponCode)
            .set('nCustomerId', nCustomerId)
            .set('cDeviceId', cDeviceId)
            .set('dtdate', dtdate)
            .set('cJsonCartData', cJsonCartData)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCartType', nCartType)
            .set('nAddressId', nAddressId)
            .set('nStoreId', nStoreId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'offer.asmx/CheckCouponCodeNewV1', { params: param1 });
    }

    public getContactUSDetails(cPageName): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cPageName', cPageName)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'customer.asmx/SelectContentByPageName', { params: param1 });
    }

    public SendContactUsMail(cName, cEmail, cMobileno, cMessage): Observable<ProductItem> {
        let param1 = new HttpParams().set('cName', cName)
            .set('cEmail', cEmail)
            .set('cMobileno', cMobileno)
            .set('cMessage', cMessage)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'customer.asmx/SendContactUsMail', { params: param1 });
    }

    public GetAllLabel(cUpdatedDateTime): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cUpdatedDateTime', cUpdatedDateTime)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'LabelData.asmx/GetAllLabel', { params: param1 });
    }

    public SelectAllVideoCategory(nFromId, nToId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Category.asmx/SelectAllVideoCategory', { params: param1 });
    }

    public GetVideoListIdWise(nCategoryId, nFromId, nToId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nCategoryId", nCategoryId)
            .set("nLanguageId", this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Category.asmx/GetVideoListIdWise', { params: param1 });
    }

    public SelectAllOrderByCustomerWise(nCustomerId, cOrderType): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nCustomerId", nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('cOrderType', cOrderType)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'order.asmx/SelectAllOrderByCustomerWise', { params: param1 });
    }

    public SelectAllOrderByCustomerWiseV1(nCustomerId, cOrderType, nFromId, nToId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nCustomerId", nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('cOrderType', cOrderType)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'order.asmx/SelectAllOrderByCustomerWiseV1', { params: param1 });
    }

    public SelectRowOrderV2(nOrderId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nOrderId", nOrderId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'order.asmx/SelectRowOrder', { params: param1 });
    }

    public SelectRowOrderV3(nOrderId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nOrderId", nOrderId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'order.asmx/SelectRowOrderV3', { params: param1 });
    }

    public SelectRowOrderV4(nOrderId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set("nOrderId", nOrderId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'order.asmx/SelectRowOrderV4', { params: param1 });
    }



    public UpdateCustomerPassword(nCustomerId, cNewPassword, cOldPassword): Observable<any> {
        let param1 = new HttpParams()
            .set("nCustomerId", nCustomerId)
            .set('cNewPassword', cNewPassword)
            .set('cOldPassword', cOldPassword)
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'customer.asmx/UpdateCustomerPassword', { params: param1 });
    }

    public SelectSearchProduct(cSearchString, FromId, To, cFilterType): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cSearchString', cSearchString)
            .set('nLanguageId', this.nLanguageId)
            .set('nFromId', FromId)
            .set('nToId', To)
            .set('cToken', this.cTokenTahani)
            .set('cFilterType', cFilterType)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'product.asmx/SelectSearchProductV1', { params: param1 });
    }


    public GetCategoryNameenglishandArabicForIdWise(nCategoryId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nCategoryId', nCategoryId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Category.asmx/GetCategoryNameenglishandArabicForIdWise', { params: param1 });
    }

    public SelectAllStoreByLanguageId(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Store.asmx/SelectAllStoreByLanguageId', { params: param1 });
    }

    public SelectAllStoreById(StoreId): Observable<any> {
        let param1 = new HttpParams().set('UserId', this.nUserId)
            .set('StoreId', StoreId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Store.asmx/SelectAllStoreById', { params: param1 });
    }

    public SelectAllBouquetSize(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Product.asmx/SelectAllBouquetSize', { params: param1 });
    }

    public SelectAllBouquetProduct(cCategoryName, nFromId, nToId, nCategoryId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('cCategoryName', cCategoryName)
            .set('nCategoryId', nCategoryId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Product.asmx/SelectAllBouquetProductV1', { params: param1 });
    }

    public SelectAllBouquetProductWrapperandRibbon(cSearchString, cCategoryName): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cSearchString', cSearchString)
            .set('cCategoryName', cCategoryName)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Product.asmx/SelectAllBouquetProduct', { params: param1 });
    }


    public SelectAllBouquetStyle(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'Product.asmx/SelectAllBouquetStyle', { params: param1 });
    }

    public GetAllBrandList(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'customer.asmx/GetAllBrandList', { params: param1 });
    }

    public SelectAllBrand(): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<any>(this.urlTahani + 'customer.asmx/SelectAllBrand', { params: param1 });
    }


    public SelectAllProductByCategoryV1(nCategoryId, nFromId, nToId, cAttributeJsonData, nBrandId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCategoryId', nCategoryId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cFilterType', '1')
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('cAttributeJsonData', cAttributeJsonData)
            .set('nBrandId', nBrandId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/SelectAllProductByCategoryV1', { params: param1 });
    }

    public SubscriptionProduct(cFilterType, nFromId, nToId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cFilterType', cFilterType)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'subscription.asmx/SubscriptionProduct', { params: param1 });
    }

    public GetSubscriptionTimeSlot(): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'deliverytime.asmx/GetSubscriptionTimeSlot', { params: param1 });
    }

    public getCalculateSubscription(nCustomerId, cJsonData, nAddressId, cCouponCode, cDates): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('nCustomerId', nCustomerId)
            .set('cJsonData', cJsonData)
            .set('nAddressId', nAddressId)
            .set('cToken', this.cTokenTahani)
            .set('cCouponCode', cCouponCode)
            .set('cDates', cDates)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'subscription.asmx/CalculateSubscription', { params: param1 });
    }


    public CreateSubscription(cJsonData, nCustomerId, nAddressId, nProductId, cTime, fTotal, fDeliveryCharge, cPaymentMode, fDiscount, cCouponCode, cDates): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('nCustomerId', nCustomerId)
            .set('cJsonData', cJsonData)
            .set('nAddressId', nAddressId)
            .set('nProductId', nProductId)
            .set('cTime', cTime)
            .set('fTotal', fTotal)
            .set('fDeliveryCharge', fDeliveryCharge)
            .set('cPaymentMode', cPaymentMode)
            .set('cToken', this.cTokenTahani)
            .set('fDiscount', fDiscount)
            .set('cCouponCode', cCouponCode)
            .set('fTotal', fTotal)
            .set('cDates', cDates)
            .set('cDeviceName', this.cDeviceName)
            .set('cAppVersion', this.cAppVersion)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'subscription.asmx/CreateSubscription', { params: param1 });
    }


    public SubscriptionDetails(nCustomerId, nSubscriptionId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set("nCustomerId", nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nSubscriptionId', nSubscriptionId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'subscription.asmx/SubscriptionDetails', { params: param1 });
    }

    public CustomerWiseSubscription(nCustomerId, nFromId, nToId): Observable<any> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set("nCustomerId", nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'subscription.asmx/CustomerWiseSubscription', { params: param1 });
    }


    public GetCartAndAllDataV1(nCustomerId, cDeviceId, nAddressId, nCartType): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cDeviceId', cDeviceId)
            .set('nAddressId', nAddressId)
            .set('nCartType', nCartType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/GetCartAndAllDataV1', { params: param1 });
    }


    public GetCartAndAllDataV2(nCustomerId, cDeviceId, nAddressId, nCartType, nStoreId): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCustomerId', nCustomerId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('cDeviceId', cDeviceId)
            .set('nAddressId', nAddressId)
            .set('nCartType', nCartType)
            .set('nStoreId', nStoreId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Cart.asmx/GetCartAndAllDataV2', { params: param1 });
    }

    public ServicesProduct(nProductId, nFromId, nToId, nAssigneCategoryIndexId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nProductId', nProductId)
            .set('nLanguageId', this.nLanguageId)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nAssigneCategoryIndexId', nAssigneCategoryIndexId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/ServicesProduct', { params: param1 });
    }

    public SelectAllBouquetOrders(nFromId, nToId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nFromId', nFromId)
            .set('nToId', nToId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'BouquetOrder.asmx/SelectAllBouquetOrders', { params: param1 });
    }

    public ExecutePayment(nPaymentMethod, fInvoiceAmount, nCustomerId, nOrderId, cOrderType): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nPaymentMethod', nPaymentMethod)
            .set('fInvoiceAmount', fInvoiceAmount)
            .set('nCustomerId', nCustomerId)
            .set('nOrderId', nOrderId)
            .set('cOrderType', cOrderType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'MyFatoorahPayment.asmx/ExecutePayment', { params: param1 });
    }

    public UpdatePaymentStatusV2(nOrderId, nCustomerId, fAmount, cPaymentTerms, cInvoiceId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nOrderId', nOrderId)
            .set('nCustomerId', nCustomerId)
            .set('fAmount', fAmount)
            .set('cPaymentTerms', cPaymentTerms)
            .set('cToken', this.cTokenTahani)
            .set('cInvoiceId', cInvoiceId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'order.asmx/UpdatePaymentStatusV2', { params: param1 });
    }


    public UpdatePaymentSubscription(nSubscriptionId, cReferenceNumber, cPaymentToken, cPaymentTerms, cNotes): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nSubscriptionId', nSubscriptionId)
            .set('cReferenceNumber', cReferenceNumber)
            .set('cPaymentToken', cPaymentToken)
            .set('cPaymentTerms', cPaymentTerms)
            .set('cNotes', cNotes)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'subscription.asmx/UpdatePaymentSubscription', { params: param1 });
    }

    public BouquetOrderDetails(nOrderId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nOrderId', nOrderId)
            .set('nLanguageId', this.nLanguageId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'BouquetOrder.asmx/BouquetOrderDetails', { params: param1 });
    }

    public getCategoryIdCategoryNameWise(cCategoryName): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('cCategoryName', cCategoryName)
            .set('nLanguageId', this.nLanguageId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'Category.asmx/getCategoryIdCategoryNameWise', { params: param1 });
    }

    public getProductIdProductNameWise(cProductName): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('cProductName', cProductName)
            .set('nLanguageId', this.nLanguageId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'product.asmx/getProductIdProductNameWise', { params: param1 });
    }

    public ForgotPassword(cEmail): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('cEmail', cEmail);
        return this.http.get<ProductItem>(this.urlTahani + 'login.asmx/ForgotPassword', { params: param1 });
    }

    public SelectCustomerWalletDetail(nCustomerId): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nCustomerId', nCustomerId)
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nLanguageId', this.nLanguageId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'customerwallet.asmx/SelectCustomerWalletDetail', { params: param1 });
    }

    public getBrandIdBrandNameWise(cBrandName): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('cBrandName', cBrandName)
            .set('nLanguageId', this.nLanguageId)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'customer.asmx/getBrandIdBrandNameWise', { params: param1 });
    }


    public GetPaymentStatusRequest(Key, KeyType): Observable<ProductItem> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('Key', Key)
            .set('KeyType', KeyType)
            .set('nCountryId', this.nCountryId);
        return this.http.get<ProductItem>(this.urlTahani + 'MyFatoorahPayment.asmx/GetPaymentStatusRequest', { params: param1 });
    }

    public SelectAllStore(): Observable<any> {
        let param1 = new HttpParams()
            .set('UserId', this.nUserId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'Store.asmx/SelectAllStore', { params: param1 });
    }

    public GetLanguageDataByLabelName(cLableName): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nLanguageId', this.nLanguageId)
            .set('cLableName', cLableName)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'LabelData.asmx/GetLanguageDataByLabelName', { params: param1 });
    }


    public GetProductName(nProductId): Observable<any> {
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nProductId', nProductId)
            .set('cToken', this.cTokenTahani)
            .set('nCountryId', this.nCountryId)
        return this.http.get<any>(this.urlTahani + 'product.asmx/GetProductName', { params: param1 });
    }

    public SelectAllCountryByLanguageId(): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nLanguageId', "1")
            .set('cToken', this.cTokenTahani)

        return this.http.get<ProductItem>(this.urlTahani + 'Country.asmx/SelectAllCountryByLanguageId', { params: param1 });
    }

    public SelectCountryWiseCurrency(): Observable<ProductItem> {
        let param1 = new HttpParams().set('nUserId', this.nUserId)
            .set('nCountryId', this.nCountryId)
        return this.http.get<ProductItem>(this.urlTahani + 'currency.asmx/SelectCountryWiseCurrency', { params: param1 });
    }

    public cancelorder(nOrderId,cStatus,nCustomerId): Observable<any> {
        
        let param1 = new HttpParams()
            .set('nUserId', this.nUserId)
            .set('nOrderId', nOrderId.toString())
            .set('nCustomerId', nCustomerId)
            .set('cStatus', cStatus)
            .set('cToken', this.cTokenTahani)
         return this.http.get<any>(this.urlTahani + 'order.asmx/UpdateOrderStatusCustomer', { params: param1 });
    }
 
} 
